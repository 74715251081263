import https from "./https"
import store from "@/store"

const tagApi = {
  getTags(organization, params) {
    return https.get(`admin/organizations/${organization.id}/tags`, {params})
  },
  getTag(organization, tagId) {
    return https.get(`admin/organizations/${organization.id}/tags/${tagId}`)
  },
  updateTag(organization, params) {
    return https.put(`admin/organizations/${organization.id}/tags/${params.id}`, params)
  },
  removeTag(organization, params) {
    const url = 'admin/organizations/' + organization.id + '/tags/' + params.id;
    return https.delete(url)
  },
  storeTag (params) {
    const organization = store.state.general.organization
    const url = 'admin/organizations/' + organization.id  + '/tags'

    return https.post(url, params)
  },
  syncFromCresclabTag(organization) {
    return https.post(`admin/organizations/${organization.id}/tags/sync-from-cresclab`)
  }
}

export default tagApi
